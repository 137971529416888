var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.savePassword.apply(null, arguments)}}},[(_vm.mode === 'registration')?[_c('h3',{staticClass:"is-size-3"},[_vm._v("Enter your name:")]),_c('b-field',{attrs:{"type":_vm.validation.hasError('firstName') ? 'is-danger' : '',"message":_vm.validation.hasError('firstName')
              ? _vm.validation.firstError('firstName')
              : ''}},[_c('float-label',[_c('input',{directives:[{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"}],staticClass:"input",attrs:{"placeholder":"First"},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing)return;_vm.firstName=$event.target.value}}})])],1),_c('b-field',{attrs:{"type":_vm.validation.hasError('lastName') ? 'is-danger' : '',"message":_vm.validation.hasError('lastName')
              ? _vm.validation.firstError('lastName')
              : ''}},[_c('float-label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"}],staticClass:"input",attrs:{"placeholder":"Last"},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing)return;_vm.lastName=$event.target.value}}})])],1)]:_vm._e(),_c('h3',{staticClass:"is-size-3"},[_vm._v("Choose a password:")]),_c('p',[_vm._v("Must be at least 8 characters long.")]),_c('b-field',{attrs:{"type":_vm.validation.hasError('password') ? 'is-danger' : '',"message":_vm.validation.hasError('password')
            ? _vm.validation.firstError('password')
            : ''}},[_c('float-label',[(_vm.mode === 'registration')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"input",attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}):_c('input',{directives:[{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"input",attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})])],1),_c('b-field',{attrs:{"type":_vm.validation.hasError('passConfirm') ? 'is-danger' : '',"message":_vm.validation.hasError('passConfirm')
            ? _vm.validation.firstError('passConfirm')
            : ''}},[_c('float-label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passConfirm),expression:"passConfirm"}],staticClass:"input",attrs:{"type":"password","placeholder":"Confirm"},domProps:{"value":(_vm.passConfirm)},on:{"input":function($event){if($event.target.composing)return;_vm.passConfirm=$event.target.value}}})])],1),_vm._m(0)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"control action"},[_c('button',{staticClass:"button is-primary is-rounded",attrs:{"type":"submit"}},[_vm._v("Save Password")])])
}]

export { render, staticRenderFns }