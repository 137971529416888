<template>
  <div class="validation-code-input">
    <!--    eslint-disable-next-line-->
    <input type="number" pattern="[0-9]{1}" ref="input1" v-model="value[0]" />
    <!--    eslint-disable-next-line-->
    <input type="number" pattern="[0-9]{1}" ref="input2" v-model="value[1]" />
    <!--    eslint-disable-next-line-->
    <input type="number" pattern="[0-9]{1}" ref="input3" v-model="value[2]" />
    <span class="code-separator">-</span>
    <!--    eslint-disable-next-line-->
    <input type="number" pattern="[0-9]{1}" ref="input4" v-model="value[3]" />
    <!--    eslint-disable-next-line-->
    <input type="number" pattern="[0-9]{1}" ref="input5" v-model="value[4]" />
    <!--    eslint-disable-next-line-->
    <input type="number" pattern="[0-9]{1}" ref="input6" v-model="value[5]" />
  </div>
</template>

<script>
export default {
  name: 'ValidationCodeInput',
  props: {
    value: {
      type: Array,
      default: () => ['', '', '', '', '', ''],
    },
  },
  mounted() {
    this.$refs.input1.focus();
  },
  watch: {
    value: function value(newDigits) {
      let filled = 0;
      for (let i = 0; i < 5; i += 1) {
        if (newDigits[i].length) {
          filled += 1;
        }
      }
      if (this.value[filled].length === 0) {
        this.$refs[`input${filled + 1}`].focus();
      }
      if (filled === 5 && newDigits[5].length) {
        this.$emit('input', newDigits);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.validation-code-input input {
  width: 32px;
  text-align: center;
  margin: 5px;
  font-size: 36px;
}

span.code-separator {
  font-size: 36px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
