import axios from 'axios';

axios.defaults.params = { _format: 'json' };
axios.defaults.baseURL = 'https://backend.bcubed.io/';

export default {
  install(Vue) {
    Vue.prototype.$get = function (url, minDuration) {
      if (minDuration) {
        return Promise.all([
          axios.get(url),
          new Promise((resolve) => setTimeout(resolve, minDuration)),
        ]).then((data) => data.shift());
      }
      return axios.get(url);
    };

    Vue.prototype.$put = function (url, data, minDuration) {
      const promise = axios.put(url, data).catch((err) => {
        if (
          err.response
          && (err.response.data.message
            === 'X-CSRF-Token request header is missing'
            || err.response.data.message
              === 'X-CSRF-Token request header is invalid')
        ) {
          axios.get('/session/token').then((token) => {
            axios.defaults.headers.post['X-CSRF-Token'] = token.data;
            axios.defaults.headers.put['X-CSRF-Token'] = token.data;
            axios.defaults.headers.delete['X-CSRF-Token'] = token.data;
            return axios.put(url, data);
          });
        } else {
          console.error("Error data: ", err.response.data)
          throw err;
        }
      });
      if (minDuration) {
        return Promise.all([
          promise,
          new Promise((resolve) => setTimeout(resolve, minDuration)),
        ]).then((promiseData) => promiseData.shift());
      }
      return promise;
    };

    Vue.prototype.$post = function (url, data, minDuration) {
      const promise = axios.post(url, data).catch((err) => {
        if (
          err.response
          && (err.response.data.message
            === 'X-CSRF-Token request header is missing'
            || err.response.data.message
              === 'X-CSRF-Token request header is invalid')
        ) {
            return axios.get('/session/token').then((token) => {
              axios.defaults.headers.post['X-CSRF-Token'] = token.data;
              axios.defaults.headers.put['X-CSRF-Token'] = token.data;
              axios.defaults.headers.delete['X-CSRF-Token'] = token.data;
              return axios.post(url, data);
            });          
        }
        throw err;
      });
      if (minDuration) {
        return Promise.all([
          promise,
          new Promise((resolve) => setTimeout(resolve, minDuration)),
        ]).then((promiseData) => promiseData.shift());
      }
      return promise;
    };

    Vue.prototype.$delete = function (url, minDuration) {
      const promise = axios.delete(url).catch((err) => {
        if (
          err.response
          && (err.response.data.message
            === 'X-CSRF-Token request header is missing'
            || err.response.data.message
              === 'X-CSRF-Token request header is invalid')
        ) {
          axios.get('/session/token').then((token) => {
            axios.defaults.headers.post['X-CSRF-Token'] = token.data;
            axios.defaults.headers.put['X-CSRF-Token'] = token.data;
            axios.defaults.headers.delete['X-CSRF-Token'] = token.data;
            return axios.delete(url);
          });
        } else {
          throw err;
        }
      });
      if (minDuration) {
        return Promise.all([
          promise,
          new Promise((resolve) => setTimeout(resolve, minDuration)),
        ]).then((data) => data.shift());
      }
      return promise;
    };

    Vue.prototype.$setCSRFToken = function (token) {
      axios.defaults.headers.post['X-CSRF-Token'] = token;
      axios.defaults.headers.put['X-CSRF-Token'] = token;
      axios.defaults.headers.delete['X-CSRF-Token'] = token;
    };

    Vue.prototype.$setAPIKey = function(key) {
      axios.defaults.headers.common['api-key'] = key;
      axios.defaults.params['api-key'] = key ;
    }
  },
};
