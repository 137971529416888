<template>
  <div>
    <form v-if="show === 'form'" @submit.prevent="validateCode">
      <ul v-if="mode === 'registration'" class="steps is-medium is-centered has-content-centered">
        <li class="steps-segment is-active has-gaps">
            <span class="steps-marker is-primary">
              <b-icon icon="at" />
            </span>
          <div class="steps-content">
            <p class="heading">Email Address</p>
          </div>
        </li>
        <li class="steps-segment is-active has-gaps">
            <span class="steps-marker is-primary">
              <b-icon icon="account" />
            </span>
          <div class="steps-content">
            <p class="heading">Account Information</p>
          </div>
        </li>
        <li class="steps-segment">
            <span class="steps-marker is-primary is-hollow">
              <b-icon icon="check" />
            </span>
          <div class="steps-content">
            <p class="heading">Complete</p>
          </div>
        </li>
      </ul>
      <h3 v-if="mode !== 'password-reset'" class="subtitle">
        For your privacy and security, we require all accounts to have a
        validated email address. Don't worry, you will only have to do this
        once.
      </h3>
      <label class="label is-medium">Enter Validation Code</label>
      <validation-code-input v-model="validationCode" />
      <p>
        A validation code has been sent to
        <strong>{{ email }}</strong>.
        <a @click="this.showEmailOptionsDialog = true">Didn't receive email?</a>
      </p>
    </form>
    <spinner v-else-if="show==='loading'" label="Loading..."></spinner>
    <b-modal :active.sync="showEmailOptionsDialog">
      <div class="box">
        <form @submit.prevent="requestEmailValidation">
          <h4 class="title is-size-4">Resend Validation Code</h4>
          <b-field
              :type="validation.hasError('email') ? 'is-danger' : ''"
              :message="
                  validation.hasError('email')
                    ? validation.firstError('email')
                    : ''
                "
          >
            <float-label :class="{ error: validation.hasError('email') }">
              <input
                  class="input"
                  v-focus
                  disabled="disabled"
                  :value="email"
                  placeholder="Email Address"
              />
            </float-label>
          </b-field>
          <div class="control action">
            <button type="submit" class="button is-primary is-rounded">
              Send Validation Code
            </button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Send validation code and validate and trigger validated event.
import Spinner from "@/components/utility/Spinner.vue";
import ValidationCodeInput from "@/components/utility/ValidationCodeInput.vue";
import FloatLabel from "vue-float-label/components/FloatLabel.vue";
import SimpleVueValidator from 'simple-vue-validator';

SimpleVueValidator.setMode('conservative');
const { Validator } = SimpleVueValidator;
export default {
  mixins: [SimpleVueValidator.mixin],
  directives: {
    focus: {
      inserted: function inserted(el) {
        if (el.tagName === 'INPUT') {
          el.focus();
        } else {
          el.querySelector('input').focus();
        }
      },
    },
  },
  components: {
    Spinner,
    ValidationCodeInput,
    FloatLabel,
  },
  props: {
    mode: {
      type: String,
      default: 'registration', // or 'password-reset' or 'account-validation'
    },
    email: String,
    id: String,
  },
  data() {
    return {
      show: 'loading',
      validationCode: ['', '', '', '', '', ''],
      showEmailOptionsDialog: false,
    };
  },
  methods: {
    validateCode() {
      this.$post('/register/validate', {
        code: this.validationCodeString,
        email: this.email,
        id: this.id,
      }).then((response) => {
            switch (response.data.status) {
              case 'success':
                this.$emit('validated', response.data.data.id);
                break;
              case 'invalid':
                break;
              case 'expired':
                this.showEmailOptionsDialog = true;
                break;
              default:
                break;
            }
          })
        .catch((e) => {
          // @TODO: Show error to user.
          this.$emit('error', e);
          console.log(e);
        });
    },
    requestEmailValidation() {
      this.$put('/register/validate', {
        email: this.email ? this.email : '',
        id: this.id ? this.id : '',
      }).then(() => {
        this.showEmailOptionsDialog = false;
        this.show = 'form';
      }).catch((e) => {
        // @todo: Show error to user.
        this.$emit('error', e);
        console.log(e);
      });
    }
  },
  computed: {
    validationCodeString: function validationCodeString() {
      return this.validationCode.join('').length === 6
          ? `${this.validationCode[0]}${this.validationCode[1]}${
              this.validationCode[2]
          }-${this.validationCode[3]}${this.validationCode[4]}${
              this.validationCode[5]
          }`
          : '';
    },
  },
  watch: {
    validationCodeString: function validationCodeString(newCode) {
      if (newCode.length) {
        this.validateCode();
      }
    },
  },
  mounted() {
    this.requestEmailValidation();
  },
  validators: {
    email: function email(value) {
      return Validator.value(value).required().email();
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
