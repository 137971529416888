<template>
  <div>
    <div class="footer-top">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <div class="level-item has-text-centered mt-6">
              <div>
                <p class="heading">Provided By</p>
                <a target="_blank" href="https://adtumbler.com"
                   class="is-flex is-align-items-center has-text-centered"
                   style="height:70px;"
                >
                  <img src="/assets/adtumbler-logo.png" alt="adTumbler Inc." />
                </a>
              </div>
            </div>
          </div>
          <div class="level-item has-text-centered mt-6">
            <div>
              <p class="heading">Sponsored By</p>
              <a target="_blank" href="https://stephaniesmiles.org/">
                <img
                    src="/assets/stephaniesmileslogo.png"
                    alt="Stephanie Smiles"
                    title="Stephanie Smiles"
                />
              </a>
            </div>
          </div>
          <div class="level-right mt-6">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Sponsored By</p>
                <a target="_blank" href="https://operationsavannah.org/">
                  <img
                      src="/assets/opsavannah.png"
                      alt="Operation Savannah, Inc."
                      title="Operation Savannah, Inc."
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <router-link class="level-item" to="/pages/terms"> Terms &amp; Conditions </router-link>
            <router-link class="level-item" to="/pages/privacy"> Privacy Policy </router-link>
            <router-link class="level-item" to="/pages/returns"> Returns </router-link>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'footer-component'
}
</script>

<style lang="scss" scoped>
.footer-top {
  margin-bottom: 20px;
}
</style>
