<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">

// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #46b4e0;
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  ),
  "twitter": (
    $twitter,
    $twitter-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;
$input-height: 2.5em;

// Import Bulma and Buefy styles
@import "~bulma/sass/base/_all";
@import "~bulma/sass/elements/_all";
@import "~bulma/sass/form/_all";
@import "~bulma/sass/components/_all";
@import "~bulma/sass/grid/_all";
@import "~bulma/sass/helpers/_all";
@import "~bulma/sass/layout/_all";
@import "~buefy/src/scss/buefy";
@import "~bulma-o-steps/bulma-steps";

.select select {
  height: $input-height
}

.steps-segment:after {
  background-color: $primary;
}

.field div.vfl-has-label {
  display: inline-block;
}

// .field {
//   margin: 10px;
// }

div.vfl-has-label .input {
  font-size: 1.5em;
  border: none;
  border-bottom: 2px solid #ddd;
  transition: border-bottom-color 0.2s ease;
  border-radius: 0;
  box-shadow: none;
  height: 1.5em;
  padding: 5px 0;
}

div.vfl-has-label .input:focus {
  border: none;
  border-bottom: 2px solid #62acd4;
  outline: none;
  box-shadow: none !important;
}

div.vfl-has-label .input:disabled {
  border: none;
  background-color: white;
  cursor: default;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
}

.b-radio.radio {
  margin-bottom: 0.25em;
}

.b-radio.radio + .radio {
  margin-left: 0;
}

.b-radio.radio:not(:last-child) {
  margin-right: 0.5em;
}

.b-radio.radio input[type="radio"] + .check {
  flex: none;
}

.loadable-container {
  position: relative;
}

.tag .mdi-24px.mdi:before {
  font-size: 1.5em;
  margin-right: 6px;
}

.navbar-item img {
  max-height: 3rem;
}

.border-right {
  border-right: 1px solid #ddd;
  @include mobile {
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
}

.countdown-counter {
  display: block;
  font-weight: 200;
  text-align: center;
  @include mobile {
    font-size: 2rem;
    line-height: 2rem;
  }
  @include tablet {
    font-size: 3rem;
    line-height: 3rem;
  }
  @include desktop {
    font-size: 4rem;
    line-height: 4rem;
  }
  @include widescreen {
    font-size: 5rem;
    line-height: 5rem;
  }
}

.countdown-label {
  display: block;
  text-transform: uppercase;
  text-align: center;
  @include mobile {
    font-size: 0.75rem;
  }
  @include tablet {
    font-size: 1rem;
  }
  @include desktop {
    font-size: 1.2rem;
  }
  @include widescreen {
    font-size: 1.5rem;
  }
}

.column.counter {
  position: relative;
  padding-right: 25px;
}

.column.counter-message {
  padding-left: 25px;
}

.column.counter::after {
  content: " ";
  background: #ddd;
  width: 2px;
  position: absolute;
  top: 25%;
  bottom: 25%;
  right: 0;
  @include mobile {
    display: none;
  }
}

.dropdown-menu {
  padding-top: 6px;
}

.dropdown-content::before {
  content: " ";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  filter: drop-shadow(0 -1px 0.3px hsla(0, 0%, 4%, 0.2));
}

.dropdown:not(.is-bottom-left) .dropdown-content:before {
  left: 40%;
}
.dropdown.is-bottom-left .dropdown-content:before {
  right: 40%;
}
</style>
