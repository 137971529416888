<template>
  <div class="ajax-progress ajax-progress-fullscreen">
    <div class="spinner-inner">
      <div class="spinner-throbber">
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
      <p class="spinner-label">{{ label }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'spinner-component',
  props: {
    label: {
      type: String,
      default: 'Loading...',
    },
  },
};
</script>

<style scoped>
.ajax-progress.ajax-progress-fullscreen {
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
  height: 100%;
  position: absolute;
}

.spinner-inner {
  top: 40%;
  width: 300px;
  position: relative;
  margin: auto;
}

.spinner-throbber {
  width: 80px;
  position: relative;
  margin: auto;
}

p.spinner-label {
  text-align: center;
}

.spinner {
  width: 70px;
}
.spinner >
div {
  background: #2c3e50;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-block;
  -moz-animation: bouncedelay 1.4s infinite ease-in-out;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.spinner .bounce1 {
  -moz-animation-delay: -0.32s;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -moz-animation-delay: -0.16s;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-moz-keyframes bouncedelay {
  0%, 80%, 100% {
    -moz-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bouncedelay {
  0%, 80%, 100% {
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

</style>
