<template>
  <div>
    <form @submit.prevent="savePassword">
      <template v-if="mode === 'registration'">
        <h3 class="is-size-3">Enter your name:</h3>
        <b-field
            :type="validation.hasError('firstName') ? 'is-danger' : ''"
            :message="
              validation.hasError('firstName')
                ? validation.firstError('firstName')
                : ''
            "
        >
          <float-label>
            <input class="input" v-focus v-model="firstName" placeholder="First" />
          </float-label>
        </b-field>
        <b-field
            :type="validation.hasError('lastName') ? 'is-danger' : ''"
            :message="
              validation.hasError('lastName')
                ? validation.firstError('lastName')
                : ''
            "
        >
          <float-label>
            <input class="input" v-model="lastName" placeholder="Last" />
          </float-label>
        </b-field>
      </template>
      <h3 class="is-size-3">Choose a password:</h3>
      <p>Must be at least 8 characters long.</p>
      <b-field
          :type="validation.hasError('password') ? 'is-danger' : ''"
          :message="
            validation.hasError('password')
              ? validation.firstError('password')
              : ''
          "
      >
        <float-label>
          <input
              v-if="mode === 'registration'"
              class="input"
              type="password"
              v-model="password"
              placeholder="Password"
          />
          <input
              v-else
              v-focus
              class="input"
              type="password"
              v-model="password"
              placeholder="Password"
          />
        </float-label>
      </b-field>
      <b-field
          :type="validation.hasError('passConfirm') ? 'is-danger' : ''"
          :message="
            validation.hasError('passConfirm')
              ? validation.firstError('passConfirm')
              : ''
          "
      >
        <float-label>
          <input class="input" type="password" v-model="passConfirm" placeholder="Confirm" />
        </float-label>
      </b-field>
      <div class="control action">
        <button type="submit" class="button is-primary is-rounded">Save Password</button>
      </div>
    </form>
  </div>
</template>

<script>
import FloatLabel from "vue-float-label/components/FloatLabel.vue";
import SimpleVueValidator from "simple-vue-validator";
SimpleVueValidator.setMode('conservative');
const { Validator } = SimpleVueValidator;

export default {
  mixins: [SimpleVueValidator.mixin],
  directives: {
    focus: {
      inserted: function inserted(el) {
        if (el.tagName === 'INPUT') {
          el.focus();
        } else {
          el.querySelector('input').focus();
        }
      },
    },
  },
  props: {
    mode: {
      type: String,
      default: 'registration', // or 'password-reset'
    },
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    FloatLabel,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      password: '',
      passConfirm: '',
    }
  },
  methods: {
    savePassword() {
      if (this.mode === 'registration') {
        this.registerPassword();
      }
      else {
        this.resetPassword();
      }
    },
    registerPassword() {
      this.$post('/register/save', {
        id: this.userId,
        password: this.password,
        first_name: this.firstName,
        last_name: this.lastName,
      }).then((response) => {
          if (response.data.status === 'success') {
            this.$emit('saved', response.data);
          }
          else {
            console.error("Password save failed.", response.data);
            this.$emit('error', response.data);
          }
        }).catch((error) => {
          console.log(error);
          this.$emit('error', error);
        });
    },
    resetPassword() {
      // @TODO: check whether this is safe. ID is a UUID and hard to guess, but who knows?
      this.$post('/api/user/password', {
        id: this.userId,
        password: this.password,
      }).then((response) => {
        this.$emit('saved', response.data);
      }).catch((e) => {
        console.error('Password reset failed', e);
        this.$emit('error', e);
      });
    },
  },
  validators: {
    email: function email(value) {
      return Validator.value(value)
          .required()
          .email()
          .custom(() => {
            if (this.emailIsTaken) {
              this.emailIsTaken = false;
              return 'An account has already been created with this email address.';
            }
            return undefined;
          });
    },
    organizationName: function organizationName(value) {
      return Validator.value(value).required()
    },
    firstName: function firstName(value) {
      return Validator.value(value).required();
    },
    lastName: function lastName(value) {
      return Validator.value(value).required();
    },
    password: function password(value) {
      return Validator.value(value)
          .required()
          .minLength(8, 'Password must be at least 8 characters long.');
    },
    'passConfirm, password': function passConfirmValid(passConfirm, password) {
      if (this.submitted || this.validation.isTouched('passConfirm')) {
        return Validator.value(passConfirm)
            .required()
            .match(password);
      }
      return undefined;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
