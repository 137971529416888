import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Onboarding from '../components/Onboarding.vue';
import Login from '../components/Login.vue';
import PartnerView from "@/views/PartnerView.vue";
import PartnerLogoutView from "@/views/PartnerLogoutView.vue";
import SSOView from "@/views/SSO.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Home' },
    children: [
      {
        name: 'onboarding',
        path: '',
        component: Onboarding,
        meta: { title: 'Home' },
      },
      {
        name: 'login',
        path: 'login',
        component: Login,
        meta: { title: 'Login' },
      },
    ],
    beforeEnter: (to, from, next) => {
      console.log("homepage auth middleware triggered!")
      const userObject = localStorage.getItem('me');
      if (userObject) {
        console.log("Routing to dashboard...")
        return next({
          path: '/dashboard'
        })
      }
      next();
    }
  },
  {
    path: '/support',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue'),
  },
  {
    path: '/partner/:informationSystemAuthKey',
    name: 'partner sso',
    component: PartnerView,
    props: true,
    meta: { title: 'Partner Single Sign-on' },
    children: [
      {
        name: 'partnerRegister',
        path: 'register/:accessCodeStr?',
        props: true,
        meta: { title: 'Register' },
        component: () => import(/* webpackChunkName: "partnerRegister" */ '@/components/Partner/Register.vue'),
      },
      {
        name: 'partnerLogin',
        path: 'login/:accessCodeStr?',
        props: true,
        meta: { title: 'Login' },
        component: () => import(/* webpackChunkName: "partnerLogin" */ '@/components/Partner/Login.vue'),
      },
    ]
  },
  {
    name: 'partnerLogout',
    path: '/partner/:informationSystemAuthKey/logout',
    component: PartnerLogoutView,
    props: true,
    meta: { title: 'Logout' },
  },
  {
    path: '/sso/:applicationAuthKey',
    name: 'SSOView',
    props: true,
    component: SSOView,
    meta: { title: 'Single Sign-on' },
    children: [
      {
        name: 'SSOLogin',
        path: 'login',
        meta: { title: 'Login' },
        component: () => import(/* webpackChunkName: "ssoLogin" */ '@/components/SSO/Login.vue'),
      },
    ]
  },
  {
    path: '/pages/:page',
    name: 'pages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PageView.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard page',
    meta: { title: 'Dashboard' },
    component: () =>
      import(/* webpackChunkName: "user" */ '@/components/Dashboard/Main'),
    children: [
      {
        path: '',
        meta: { title: 'Dashboard' },
        component: () =>
          import(/* webpackChunkName: "user" */ '@/components/Dashboard/Dashboard')
      },
      {
        path: 'welcome',
        meta: { title: 'Welcome' },
        component: () =>
          import(/* webpackChunkName: "user" */ '@/components/Dashboard/Welcome')
      },
      {
        name: 'Account Settings',
        path: 'account',
        meta: { title: 'Account Settings' },
        component: () =>
          import(/* webpackChunkName: "user" */ '@/components/Dashboard/AccountInfo')
      },
      {
        name: 'My Programs',
        path: 'programs',
        meta: { title: 'My Programs' },
        component: () =>
            import(/* webpackChunkName: "programs" */ '@/components/Dashboard/Programs')
      },
      {
        path: '/dashboard/*',
        name: 'Not found',
        meta: { title: '404 Not Found' },
        redirect: '/dashboard'
      }
    ],
    beforeEnter: (to, from, next) => {
      console.log("dashboard auth middleware triggered!")
      const userObject = localStorage.getItem('me');
      if (!userObject) {
        console.log("Not Loggedin! Routing to homepage...")
        return next({
          path: '/'
        })
      }
      next();
    }
  }
];

const defaultTitle = 'BCubed';
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.afterEach((to) => {
  Vue.nextTick(() => {
    let title = '';
    if (to.meta.title) {
      title = to.meta.title + ' | ' + defaultTitle;
    }
    else {
      title = defaultTitle;
    }
    document.title = title;
  });
});

export default router;
