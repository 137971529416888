<template>
  <div id="main-page">
  </div>
</template>

<script>
export default {
  name: 'partnerLogoutPage',
  props: ['informationSystemAuthKey'],
  data() {
    return {
      informationSystemObject: null,
    };
  },
  mounted() {
    this.$get(`/api/information_system/${this.informationSystemAuthKey}`).then((response) => {
      this.informationSystemObject = response['data'];
      // console.log(response)
      this.$get('/logout').then(() => {
        window.location.replace(this.informationSystemObject.url);
      }).catch((e) => {
        console.error(e);
      })
    }).catch((e) => {
      console.log(e);
      // @todo redirect to 404.
    }).finally(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
#main-page {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-wrapper {
  flex: 1;
}

.footer {
  padding: 1.5rem;
  background-color: #f0f0f0;
}

.footer-top {
  padding: 3rem 1.5rem;
  border-top: 1px solid #e0e0e0;
  background-color: #f8f8f8;
  background-image: linear-gradient(#f8f8f8, #fff);
}
</style>
