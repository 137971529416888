<template>
  <div>
    <form v-if="show === 'email-form'" @submit.prevent="show = 'account-validation'">
      <h1 class="title is-1 is-spaced">Recover Password</h1>
      <h3 class="subtitle is-3">Enter your email address to start recovering your password.</h3>
      <b-field
          :type="validation.hasError('email') ? 'is-danger' : ''"
          :message="
            validation.hasError('email') ? validation.firstError('email') : ''
          "
      >
        <float-label :class="{ error: validation.hasError('email') }">
          <b-input
              v-focus
              name="email"
              v-model="email"
              placeholder="Enter email address"
          />
        </float-label>
      </b-field>
      <div class="control action">
        <button type="submit" class="button is-primary is-rounded">
          Next
        </button>
      </div>
    </form>
    <account-validation v-else-if="show === 'account-validation'" :email="email" mode="password-reset" @validated="accountValidated" @error="emailError"></account-validation>
    <password-form v-else-if="show === 'password-form'" mode="password-reset" :user-id="id" @saved="passwordSaved" @error="passwordSaveError"></password-form>
  </div>
</template>

<script>
// on @validated from account-validation, allow password reset.
import AccountValidation from "@/components/AccountValidation.vue";
import PasswordForm from "@/components/PasswordForm.vue";
import SimpleVueValidator from "simple-vue-validator";
import FloatLabel from "vue-float-label/components/FloatLabel.vue";

SimpleVueValidator.setMode('conservative');
const { Validator } = SimpleVueValidator;
export default {
  mixins: [SimpleVueValidator.mixin],
  components: {
    PasswordForm,
    FloatLabel,
    AccountValidation,
  },
  directives: {
    focus: {
      inserted: function inserted(el) {
        if (el.tagName === 'INPUT') {
          el.focus();
        } else {
          el.querySelector('input').focus();
        }
      },
    },
  },
  props: {
    defaultEmail: String,
  },
  data() {
    return {
      show: 'email-form', // or 'account-validation' or 'password-form'
      email: this.defaultEmail,
      id: '',
    };
  },
  methods: {
    passwordSaved() {
      // redirect to login page
      this.$emit("saved")
    },
    passwordSaveError(e) {
      console.log(e);
      this.$emit('error', e);
    },
    accountValidated(id) {
      this.show = 'password-form';
      this.id = id;
    },
    emailError(e) {
      this.show = 'email-form';
      this.$emit('email-error', e)
    }
  },
  validators: {
    email: function email(e) {
      return Validator.value(e)
          .required()
          .email()
          .custom(() => {
            if (this.invalidAccount) {
              this.invalidAccount = false;
              return 'No account could be found for this email address.';
            }
            return undefined;
          });
    },
    password: function password(value) {
      return Validator.value(value)
          .required()
          .custom(() => {
            if (this.invalidPassword) {
              this.invalidPassword = false;
              return 'Incorrect password';
            }
            return undefined;
          });
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
