<template>
  <div id="main-page">
    <template v-if="loading">
      <!--      @TODO: add loading-->
    </template>
    <template v-else>
      <nav class="navbar has-shadow is-spaced">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item is-size-3" to="/">
              <h1 class=""><router-link to="/">BCubed</router-link></h1>
            </a>
            <div
                class="navbar-burger burger"
                @click="mobileMenuActive = !mobileMenuActive;"
                :class="{ 'is-active': mobileMenuActive }"
                @keydown="mobileMenuActive = !mobileMenuActive;"
            >
              <span></span> <span></span> <span></span>
            </div>
          </div>
          <div class="navbar-menu" :class="{ 'is-active': mobileMenuActive }">
            <div class="navbar-end">
              <template v-if="userLoggedOut">
                <div class="navbar-item">
                  <router-link
                      :disabled="$route.name === 'partnerLogin'"
                      class="button is-primary is-outlined"
                      to="login">
                    Login
                  </router-link>
                </div>
              </template>
              <div v-else class="navbar-item">
                <router-link class="button is-primary is-outlined" to="/dashboard">
                  Dashboard
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div class="main-wrapper">
        <div id="page-top">
          <div class="hero is-small">
            <div class="hero-body">
              <div class="container">
                <div class="columns is-vcentered reverse">
                  <div class="column loadable-container">
                    <div class="transition-wrapper">
                      <transition :name="transitionName" mode="out-in">
                        <router-view :application="application" />
                      </transition>
                    </div>
                  </div>
                  <div class="column">
                    <figure class="image is-square mt-6">
                      <img alt="office" src="/assets/hero-image.jpg" class="hero-image"/>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-component></footer-component>
    </template>
  </div>
</template>

<script>
import FooterComponent from '@/components/Layout/Footer.vue'

export default {
  name: 'partnerPage',
  components: {
    FooterComponent
  },
  props: ['applicationAuthKey'],
  data() {
    return {
      userLoggedOut: !window.me?.uuid,
      mobileMenuActive: false,
      transitionName: '',
      application: null,
      loading: true,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  mounted() {
    this.$get(`/api/application/${this.applicationAuthKey}`).then((response) => {
      this.application = response['data'];
    }).catch((e) => {
      console.log(e);
      // @todo redirect to 404.
    }).finally(() => {
      this.loading = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.name === 'SSOLogin' && from.name === 'SSORegister') {
        this.transitionName = 'slide-left';
      } else {
        this.transitionName = 'slide-right';
      }
    },
  },
};
</script>

<style scoped>
#main-page {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-wrapper {
  flex: 1;
}

.footer {
  padding: 1.5rem;
  background-color: #f0f0f0;
}

.footer-top {
  padding: 3rem 1.5rem;
  border-top: 1px solid #e0e0e0;
  background-color: #f8f8f8;
  background-image: linear-gradient(#f8f8f8, #fff);
}

.image.is-square img.hero-image {
  width: 80%;
  height: auto;
}

</style>
